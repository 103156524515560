import {NavOption} from "../types/navOption";

const entNavOptions: NavOption [] = [
    {
        name: "wildlife", 
        label: "Wildlife Presentation"
    }, 
    {   name: "character", 
        label: "Character Appearance"
    },
    {   name: "show", 
        label: "Daily Show"
    }
];

export default entNavOptions;