import { Attraction } from "../types/attraction";
import { Context } from "../types/context";


const attractionIsOpen = (attraction: Attraction, context: Context): boolean => {
    //
    if(!context.IsSummer && attraction.AttractionPark ==="WhiteWater World") return false;
    if(attraction.AttractionTemporarilyClosed ){
        return false;
    }
    if(
        (attraction.AttractionMaintenanceStart && new Date(attraction.AttractionMaintenanceStart) < context.date) && 
        (attraction.AttractionMaintenanceEnd && new Date(attraction.AttractionMaintenanceEnd) > context.date)
    ){
        return false;
    }
    return true;
};

export default attractionIsOpen;