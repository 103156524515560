import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

//Service
import useUmbracoService from '../services/UmbracoService';

//Types
import { Food } from '../types/food';

//Components
import FoodCard from './foodCard';

//Utilities
import useQueryParam from '../utilities/useSearchParams';

interface IContentProps {
	sitename: string;
	content: string;
	colour: string;
	activations: string;
}

const FoodFilter: React.FC<IContentProps> = ({ sitename, content, colour, activations }) => {
	const service = useUmbracoService(sitename, content);
	const history = useHistory();

	const [choseCuisine, setChoseCuisine] = useState(false);
	const [groupMenuOpen, setGroupMenuOpen] = useState(false);
	const [filtersOpen, setFiltersOpen] = useState(false);
	const [cuisineUrl, setCuisineUrl] = useQueryParam('cuisine', '');
	const [activationOnlyUrl, setActivationOnlyUrl] = useQueryParam('activation', 'false');
	const [accompaniedUrl, setAccompaniedUrl] = useQueryParam('accompanied', 'false');
	const [dietaryUrl, setDietaryUrl] = useQueryParam('dietary', '');
	const activationsArr = activations?.split(',');

	useEffect(
		() => {
			return history.listen((location: any) => {
				new URLSearchParams(location.search).forEach((value, key) => {
					switch (key) {
						case 'activation':
							setActivationOnlyUrl(value);
							break;
						case 'age':
							break;
						case 'cuisine':
							setCuisineUrl(value);
							break;
						default:
							break;
					}
					//history.go(-1); sort of works here, but creates a class lifecycle loop that requires a second press of browser back to work.
					history.go(-1);
				});
			});
		} // eslint-disable-line react-hooks/exhaustive-deps
	);

	const onClickFilterHandle = (e: any, filter: string, value: string) => {
		switch (filter) {
			case 'activation':
				setActivationOnlyUrl(activationOnlyUrl === 'false' ? 'true' : 'false');
				break;
			case 'attraction-group':
				setGroupMenuOpen(groupMenuOpen ? false : true);
				setChoseCuisine(false);
				break;
			case 'cuisine':
				setCuisineUrl(cuisineUrl === value ? '' : value);
				setChoseCuisine(true);
				break;
			case 'accompanied':
				setAccompaniedUrl(accompaniedUrl === 'false' ? 'true' : 'false');
				break;
			case 'dietary':
				let dietaryArr: string[] = [];
				for (let i of dietaryUrl.trim().split(',')) i && dietaryArr.push(i);
				if (dietaryArr.some(s => s === value)) {
					//remove value if it exists
					delete dietaryArr[dietaryArr.indexOf(value)];
				} else {
					//add value if it doesn't exist
					dietaryArr.push(value);
				}
				let dietString: string = dietaryArr.toString();
				dietString = dietString.startsWith(',') ? dietString.slice(1, dietString.length) : dietString;
				dietString = dietString.endsWith(',') ? dietString.slice(0, dietString.length - 1) : dietString;
				setDietaryUrl(dietString);
				break;
			default:
				setActivationOnlyUrl('');
				setCuisineUrl('');
				setChoseCuisine(false);
				setDietaryUrl('');
				break;
		}
	};

	let cuisineNav: any[] = [];
	let dietNav: any[] = [];
	let filteredData: Food[] = [];
	let foodList: any[] = [];

	if (service.status === 'loaded') {
		filteredData = service.payload.food;

		//The Umbraco React Component can send data about which activations this particular instance of the app should display food from.
		//Each food item has data describing which activations it is valid for. So we will filter them here.
		if (activationsArr?.length > 0) {
			filteredData = filteredData.filter((f: Food) => {
				return f.Activations?.some(fa => activationsArr?.some(a => a === fa.toLocaleLowerCase().replaceAll(' ', '-')));
			});
		}

		const cuisines = [...new Set(filteredData.map(c => c.Cuisine))];
		const cuisinesSecondary = [...new Set(filteredData.map(c => c.CuisineSecondary))];
		const cuisineOptions = [...new Set(cuisines.concat(cuisinesSecondary.flat()))];

		cuisineOptions.forEach((co, index) => {
			const disabled: boolean = choseCuisine
				? false
				: filteredData.some(f => f.Cuisine === co) || filteredData.some(f => f.CuisineSecondary.some(cs => cs === co))
				? false
				: true;
			cuisineNav.push(
				<li key={index} className={co === cuisineUrl ? 'active' : ''}>
					<button
						className={`btn btn-${colour} cuisine ${co.toLocaleLowerCase()} ${disabled ? 'disabled' : ''}`}
						disabled={disabled}
						onClick={e => onClickFilterHandle(e, 'cuisine', co)}>
						<img src={`/dist/images/DW-Food-Icons-${co.replaceAll(' ', '-')}.svg`} alt={co} />
						{co}
					</button>
				</li>
			);
		});

		const dietOptions = [...new Set(filteredData.map(d => d.Dietary).flat())];

		dietOptions.forEach((dietOption, index) => {
			if (dietOption !== null) {
				const disabled: boolean = filteredData.some(f => f.Dietary?.some(d => d === dietOption)) ? false : true;
				dietNav.push(
					<li key={index} className={dietaryUrl.indexOf(dietOption) > -1 ? 'active' : ''}>
						<button
							className={`btn btn-${colour} diet-option ${dietOption.toLocaleLowerCase().replaceAll(' ', '-')} ${
								disabled ? 'disabled' : ''
							}`}
							disabled={disabled}
							onClick={e => onClickFilterHandle(e, 'dietary', dietOption)}>
							<img src={`/dist/images/DW-Food-Icons-${dietOption.replaceAll(' ', '-')}.svg`} alt={dietOption} />
							{dietOption}
						</button>
					</li>
				);
			}
		});
		/*
		
			Filter data
		*/

		if (cuisineUrl) {
			filteredData = filteredData.filter((f: Food) => {
				return f.Cuisine === cuisineUrl || f.CuisineSecondary.some(cs => cs === cuisineUrl);
			});
		}

		if (dietaryUrl) {
			filteredData = filteredData.filter((f: Food) => {
				//Dietary filter needs to be exclusive. Food Items must match every filter choice
				const target = dietaryUrl.split(',').length;
				let score = 0;
				dietaryUrl.split(',').forEach(d => {
					if (f.Dietary?.find(fd => fd === d)) {
						score++;
					}
				});
				return score === target ? f : null;
			});
		}
		if (activationOnlyUrl === 'true') {
			filteredData = filteredData.filter(f => {
				return f.Activation ? f : null;
			});
		}

		foodList =
			filteredData &&
			filteredData.map((food, index) => {
				return <FoodCard key={index} food={food} colour={colour}></FoodCard>;
			});
	}

	/*

      RENDER

  */

	return (
		<div className="attractions food">
			<div className="attractions-filters">
				<button
					className={`btn more-filters ${filtersOpen ? 'open' : 'close'}`}
					onClick={e => setFiltersOpen(filtersOpen ? false : true)}>
					{' '}
					Filter the menu
				</button>

				<div className={`ride-filters ${filtersOpen ? 'open' : ''}`}>
					<div className="cuisine-nav filter-bar">
						<h4>Filter by type</h4>
						<ul className="tablets">{cuisineNav}</ul>
					</div>
					<div className="diet-nav filter-bar">
						<h4>Dietary requirements</h4>
						<ul className="tablets">{dietNav}</ul>
					</div>
					<div className="activation-only filter-bar">
						<h4>Show tasting trail</h4>
						<button
							onClick={e => onClickFilterHandle(e, 'activation', '')}
							className={`btn switch switch-${activationOnlyUrl === 'true' ? 'on' : 'off'}`}>
							<span>{activationOnlyUrl === 'true' ? 'Y' : 'N'}</span>
						</button>
					</div>
					<div className={`food-reset tablets ${filtersOpen ? 'open' : ''}`}>
						<button onClick={e => onClickFilterHandle(e, '', '')} className="btn">
							View all
						</button>
					</div>
				</div>
			</div>
			<div className={`attractions-list food-list`}>
				{foodList.length > 0
					? foodList
					: 'There are no food items fitting your criteria. Try changing some of the filters.'}
			</div>
		</div>
	);
};

export default FoodFilter;
