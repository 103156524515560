import React, {useState} from 'react';

import {AttractionEvent} from "../types/attractionEvent";
import AttractionImageUrl from '../utilities/attractionImage';
import entNavOptions from '../constants/entertainmentOptions';
interface ITimelineCardProps{
    event: AttractionEvent
}

const TimelineCard: React.FC<ITimelineCardProps> = ({event}) => {
  const attractionEvent: AttractionEvent = event
  const [isOpen, setIsOpen] = useState(false);
  if(!attractionEvent) return null;
  let t: string = attractionEvent.EntertainmentTimeDetails && attractionEvent.EntertainmentTimeDetails?.toString();
  let startTime: string = new Date(t).toLocaleTimeString(["en-US"], {
    hour: "numeric",
    hour12: true,
    minute: "2-digit",    
  });
  const thumbImageUrl: string = AttractionImageUrl(event, "thumb");
  const heroImageUrl: string = AttractionImageUrl(event, "hero");
  let days = '';
  let daysText = '';

  switch(attractionEvent.AttractionDaysAvailable?.toString()){
    case "Monday,Tuesday,Wednesday,Thursday,Friday":
      days = 'Weekdays';
      daysText = "Monday, Tuesday, Wednesday, Thursday & Friday"
    break;
    case "Saturday,Sunday":
      days = 'Weekends';
      daysText = "Saturday & Sunday"
    break;
    case "Monday,Tuesday,Wednesday,Thursday,Friday,Saturday,Sunday":
      days = 'Every day';
      daysText = 'Every day';
    break;
    case "":
      days = 'Every day';
      daysText = 'Every day';
    break;
    default:
      days = 'See schedule';
      daysText = attractionEvent.AttractionDaysAvailable?.join(' ').toString() as string;
    break;
  }
  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className={`event ${attractionEvent.EntertainmentType?.toLowerCase()}`}>
      <div className={`event-preview-${isOpen?"open" : "closed"}`}>
        <div className="event-image">
          <img src={thumbImageUrl} alt={attractionEvent.AttractionName} />         
        </div>
        <div className="event-image-full">
          <img src={heroImageUrl} alt={attractionEvent.AttractionName} />         
        </div>
        <div className="event-details">
          <p className="event-type">{entNavOptions.find(x => x.name===attractionEvent.EntertainmentType?.toLowerCase())?.label}</p>
          <h3 className="event-title">
              {event.AttractionName}
          </h3>
          {
            attractionEvent.Location
            ?<p className="event-location"><i className="icon location"></i>{attractionEvent.Location}</p>
            :''
          }

          <p className="event-time">
            {startTime}
          </p>
          { 
            isOpen
            ? daysText.length > 0 
              ? <p className="event-days-all "><i className="icon schedule"></i>{daysText}</p> 
              : ''
            : ''
            }          
            <p className="event-days">{days}</p>
        </div>
      </div>
      <button type="button" className={`btn`} onClick={handleFilterOpening}></button>
        {
          isOpen && 
            <div className="event-description">
              <div dangerouslySetInnerHTML={{__html: attractionEvent.AttractionDescription}}></div>
              {
                attractionEvent.Location &&            
                <a className="btn btn-primary" href={`/park-information/park-map/online-map/#/location/${attractionEvent.Location?.replace(' ', '%20')}`}>View on map</a>
              }
            </div>
        }
    </div>
  );
}
export default TimelineCard