import {BrowserRouter as Router} from 'react-router-dom';
import Attractions from '../src/components/attractions';
import FoodFilter from '../src/components/foodFilter'

function App(props) {
  switch(props.content){
    case "attractions" :
      return (
        <Router >
          {     
            <Attractions sitename={props.site} content={props.content}></Attractions>
          }    
        </Router>
      );
    
    case "food" :
      return (
        <Router >
          {     
            <FoodFilter sitename={props.site} content={props.content} colour={props.colour} activations={props.activations}></FoodFilter>
          }    
        </Router>
      );
  }
}

export default App;
