import React from 'react';
// Types
import { Attraction } from "../types/attraction";
import { AttractionEvent } from "../types/attractionEvent";
import { Context } from '../types/context';

import rootUrl from "../utilities/rootUrl";
import attractionIsOpen from "../utilities/attractionIsOpen";
import AttractionImageUrl from "../utilities/attractionImage"

interface IAttractionCardProps{
  attraction: Attraction
  context: Context
}

const AttractionCard: React.FC<IAttractionCardProps> = ({attraction, context}) => {
  const isOpen = attractionIsOpen(attraction, context);
  const imageUrl = AttractionImageUrl(attraction, "thumb");
  const attractionGroupClass = attraction.AttractionGroup.replace(' ', '-').toLocaleLowerCase();
  //chop the 's' off the end e.g. Thrill Rides becomes Thrill Ride
  const attractionGroupDisplay = attraction.AttractionGroup.split(' ')[0].toUpperCase();
  let times = "";
  if(attraction.ExperienceTimes && attraction.ExperienceTimes.length> 0){
      attraction.ExperienceTimes.forEach((t, i) => { 
        if(t.PeakOnly && (context.IsSchoolHoliday || context.IsWeekend)){
            times =  getTimeString(times,t, i);
        }
        else{
          if(!t.PeakOnly){
          times = getTimeString(times,t, i);
        } 
      }
    });
  }
  
  return (
    <div className="card">
      {closedMessage(attraction, context)}
      <div className="attraction-image">
        <a
          href={`${rootUrl}${attraction.AttractionUrl}`}
          style={{ backgroundImage: `url(${imageUrl})` }}>&nbsp;</a>
          {
            attraction.Express && <i className={`icon ${attraction.Express.toLowerCase()}-express`}></i>
          }
      </div>
      <div className="attraction-detail">
        <a className="attraction-title" href={`${attraction.AttractionUrl}`}>
          {attraction.AttractionName}
        </a>
        <p className="attraction-subtitle">{attraction.AttractionSubTitle}</p>
        <p className={`attraction-group ${attractionGroupClass}`}>{attractionGroupDisplay}</p>
        {times && <p className="attraction-times">{times}</p>}
      </div>
    </div>
  );
}
export default AttractionCard;

const closedMessage = (attraction: Attraction | AttractionEvent, context: Context): any => {
  if (attraction.AttractionTemporarilyClosedMessage?.length > 0) {
    return (
      <div className="closed">
        {attraction.AttractionTemporarilyClosedMessage}
      </div>
    );
  }
  else if (
    (attraction.AttractionMaintenanceStart && new Date(attraction.AttractionMaintenanceStart) < context.date) && 
    (attraction.AttractionMaintenanceEnd && new Date(attraction.AttractionMaintenanceEnd) > context.date)
          ) {
    const endDateString = attraction.AttractionMaintenanceEnd && new Date(attraction.AttractionMaintenanceEnd);
    const enddate =  endDateString && new Date(endDateString.setDate(endDateString.getDate() + 1))
    .toLocaleDateString("en-AU", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });

    //TODO Show seasonal closure message for www
    return (
      <div className="inmaintenance">
        This {attraction.AttractionPark === "Dreamworld" ? "ride" : "slide"}{" "}
        will be open from: {enddate}
      </div>
    );
  } else if(!context.parkIsOpen){
    return (
      <div className="closed">
        Closed during the winter months
      </div>
    )
  }
}

const getTimeString = (times: string, t: any, i: number) => {
  return times +=  (i > 0 ? " and " : "") + new Date(t.TimeStart).toLocaleTimeString([], {hour: "2-digit", minute: "2-digit", hour12: false});
}