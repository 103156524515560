import { useEffect, useState } from 'react';
import Axios from 'axios';
import { Service } from '../types/services';
import { Attraction } from '../types/attraction';
import { Food } from '../types/food';
import { Opening } from '../types/opening';
import rootUrl from '../utilities/rootUrl';

export interface Results {
	attractions?: Attraction[];
	openings?: Opening[];
	food: Food[];
}

const useUmbracoService = (site: string, content: string) => {
	const [result, setResult] = useState<Service<Results>>({
		status: 'loading',
	});

	const isValidPayload = (payload: any): payload is Results => {
		return typeof payload === 'object' && (('attractions' in payload && 'openings' in payload) || 'food' in payload);
	};

	useEffect(
		() => {
			const requestData = async function () {
				try {
					const res = await Axios.get(
						`${rootUrl}/Umbraco/api/content/getcontent?sitename=${site ? site : 'Dreamworld21'}&content=${content}`
					);
					if (isValidPayload(res.data)) {
						setResult({ status: 'loaded', payload: res.data });
					} else {
						setResult({
							status: 'error',
							error: {
								name: 'Invalid Format',
								message: 'API results must contain an array of Attractions and opening objects, or Food objects',
							},
						});
					}
				} catch (error) {
					setResult({ status: 'error', error });
				}
			};
			requestData();
		},
		// eslint-disable-line react-hooks/exhaustive-deps
		[]
	);

	return result;
};

export default useUmbracoService;
