import {Attraction} from "../types/attraction";
import {AttractionEvent} from "../types/attractionEvent";
import rootUrl from "./rootUrl";

const AttractionImageUrl = (attraction: Attraction | AttractionEvent, thumbOrHero: string) => {
	//Not all attractions have both pageThumbnail and hero, and some have a local url whilst some have absolute url
	return 	thumbOrHero === "thumb" ? thumbImage(attraction) : heroImage(attraction);
}
export default AttractionImageUrl
const thumbImage = (attraction: Attraction | AttractionEvent): string => {
	return attraction.PageThumbnail != null
	? attraction.PageThumbnail.startsWith("http")
			? attraction.PageThumbnail
			: rootUrl + attraction.PageThumbnail
	: heroImage(attraction);
}

const heroImage = (attraction: Attraction | AttractionEvent): string => {
	//RideHeroImage is mandatory in Umbraco
	return attraction.AttractionHeroImageUrl.startsWith("http")
		? attraction.AttractionHeroImageUrl
		: rootUrl + attraction.AttractionHeroImageUrl
}