import { NavOption } from '../types/navOption';

const levelOptions: NavOption[] = [
	{
		name: 'low',
		label: 'Low',
	},
	{
		name: 'mild',
		label: 'Mild',
	},
	{
		name: 'max',
		label: 'Max',
	},
];

export default levelOptions;
