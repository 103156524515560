import React from 'react';
// Types
import { Food } from '../types/food';
import rootUrl from '../utilities/rootUrl';

interface IFoodCardProps {
	food: Food;
	colour: string;
}

const FoodCard: React.FC<IFoodCardProps> = ({ food, colour }) => {
	const img = rootUrl + food.ImageSm;
	return (
		<div className="card">
			<div className="food-image">
				<a href={food.Link} style={{ backgroundImage: `url(${img})` }}>
					&nbsp;
				</a>
				{food.Activation && (
					<i className={`icon food-activation`}>
						<img src="/dist/images/DW-Food-Icons-Winterfest-Tasting.svg" alt={food.ActivationHeading} />
					</i>
				)}
				{food.Dietary?.length! > 0 && (
					<div className="dietary-badges">
						{food.Dietary?.map((fd, index) => (
							<div key={index} className={`dietary-badge ${fd.toLowerCase().replaceAll(' ', '-')}`}>
								<img src={`/dist/images/DW-Food-Icons-${fd.replaceAll(' ', '-')}.svg`} alt={fd} />
							</div>
						))}
					</div>
				)}
			</div>
			<div className="food-detail">
				<a className={`attraction-title text-${colour}`} href={food.Link}>
					{food.Name}
				</a>
				{food.Location ? (
					<p className="event-location">
						<i className="icon location"></i>
						{food.Location}
					</p>
				) : (
					''
				)}
				<p className="attraction-subtitle">{food.Description}</p>
				<p className={`attraction-group ${food.Cuisine && food.Cuisine.toLowerCase()}`}>
					<img src={`/dist/images/DW-Food-Icons-${food.Cuisine.replaceAll(' ', '-')}.svg`} alt={food.Cuisine} />
					{food.Cuisine}
				</p>
				{food.Activation ? (
					<p className="activation-text">
						<b>{food.ActivationHeading}</b> {food.ActivationText}
					</p>
				) : (
					''
				)}
				{food.Location ? (
					<a href={food.Location} className={`btn btn-${colour}`}>
						Map link
					</a>
				) : (
					''
				)}
			</div>
		</div>
	);
};

export default FoodCard;
