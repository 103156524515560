import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Slider from 'react-rangeslider';

//Service
import useAttractionService from '../services/AttractionService';

//Types
import { Attraction } from '../types/attraction';
import { AttractionEvent } from '../types/attractionEvent';
import { Opening } from '../types/opening';
import { Context } from '../types/context';

//Components
import AttractionCard from './attractionCard';
import TimelineCard from './timelineCard';

//Utilities
import attractionIsOpen from '../utilities/attractionIsOpen';
import useQueryParam from '../utilities/useSearchParams';

//Constants
import levelOptions from '../constants/levelOptions';

interface IAttractionsProps {
	sitename: string;
}

const Attractions: React.FC<IAttractionsProps> = ({ sitename }) => {
	const service = useAttractionService(sitename);
	const history = useHistory();

	let parkUrl: string = '';
	if (typeof window !== 'undefined') {
		parkUrl = window.location.pathname.split('/')[2];
	}
	let attractionGroupUrl: string = '';
	if (typeof window !== 'undefined') {
		attractionGroupUrl = window.location.pathname.split('/')[3];
	}
	const [choseThrill, setChoseThrill] = useState(false);
	const [groupMenuOpen, setGroupMenuOpen] = useState(false);
	const [filtersOpen, setFiltersOpen] = useState(false);
	const [groupUrl, setGroupUrl] = useQueryParam('attraction-group', attractionGroupUrl); //preserves url as filter e.g. dreamworld/thrill-rides
	const [heightUrl, setHeightUrl] = useQueryParam('height', '');
	const [thrillUrl, setThrillUrl] = useQueryParam('thrill', '');
	const [openOnlyUrl, setOpenOnlyUrl] = useQueryParam('open', 'false');
	const [ageUrl, setAgeUrl] = useQueryParam('age', '');
	const [accompaniedUrl, setAccompaniedUrl] = useQueryParam('accompanied', 'false');
	const [showPeakUrl, setShowPeakUrl] = useQueryParam('peak', 'false');
	const [entertainmentTypeUrl, setEntertainmentTypeUrl] = useQueryParam('entertainment-type', '');

	const datenow = new Date();
	let context: Context = {
		date: datenow,
		IsSummer: false,
		IsSchoolHoliday: false,
		IsWWWWeekend: datenow.getDay() === 5 || datenow.getDay() === 6 || datenow.getDay() === 0,
		IsWeekend: datenow.getDay() === 6 || datenow.getDay() === 0,
		park: parkUrl,
		parkIsOpen: true,
	};

	useEffect(() => {
		return history.listen((location: any) => {
			new URLSearchParams(location.search).forEach((value, key) => {
				switch (key) {
					case 'attraction-group':
						setGroupUrl(value);
						break;
					case 'height':
						break;
					case 'open':
						setOpenOnlyUrl(value);
						break;
					case 'age':
						break;
					case 'thrill':
						setThrillUrl(value);
						break;
					case 'peak':
						setShowPeakUrl(value);
						break;
					default:
						break;
				}
				//history.go(-1); sort of works here, but creates a class lifecycle loop that requires a second press of browser back to work.
				history.go(-1);
			});
		});
	}, [history]);

	const onClickFilterHandle = (e: any, filter: string, value: string) => {
		switch (filter) {
			case 'openOnly':
				setOpenOnlyUrl(openOnlyUrl === 'false' ? 'true' : 'false');
				break;
			case 'attraction-group':
				setGroupMenuOpen(groupMenuOpen ? false : true);
				//setGroupUrl(groupUrl === value ? "all-attractions" : value);
				setGroupUrl(value);
				//unset thrill level so user doesn't get stuck in thrill-rides/thill-level=low with no results
				setThrillUrl('');
				setChoseThrill(false);
				break;
			case 'thrill':
				setThrillUrl(thrillUrl === value ? '' : value);
				setChoseThrill(true);
				break;
			case 'accompanied':
				setAccompaniedUrl(accompaniedUrl === 'false' ? 'true' : 'false');
				break;
			case 'peak':
				setShowPeakUrl(showPeakUrl === 'false' ? 'true' : 'false');
				break;
			case 'entertainment-type':
				let entArr: string[] = entertainmentTypeUrl.trim().split(',');
				if (entArr.some(s => s === value)) {
					//remove value if it exists
					delete entArr[entArr.indexOf(value)];
				} else {
					//add value if it doesn't exist
					entArr.push(value);
				}
				let entString: string = entArr.toString();
				entString = entString.startsWith(',') ? entString.slice(1, entString.length) : entString;
				setEntertainmentTypeUrl(entString);
				break;
			default:
				setOpenOnlyUrl('');
				setThrillUrl('');
				setAccompaniedUrl('');
				setHeightUrl('');
				setAgeUrl('');
				setChoseThrill(false);
				break;
		}
	};

	const onheightSliderHandle = (value: number) => {
		setHeightUrl(value.toString());
	};

	const onAgeSliderHandle = (value: number) => {
		setAgeUrl(value.toString());
	};
	let groupNav = [];
	let thrillsNav: any[] = [];

	let filteredData: Attraction[] = [];
	let attractions: any[] = [];

	if (service.status === 'loaded') {
		//Get the next/current season.
		context.nextOpening = service.payload.openings.sort(
			(o: Opening, b: Opening) => +new Date(o.DateFrom) - +new Date(b.DateFrom)
		)[0];

		const openSeason =
			context.nextOpening &&
			new Date(context.nextOpening.DateFrom) < context.date &&
			context.date < new Date(context.nextOpening.DateTo);
		if (openSeason) {
			context.IsSummer = true;
			context.IsSchoolHoliday = context.nextOpening.IsHoliday;
		}

		if (parkUrl === 'whitewater-world') {
			// During closed season, we'd still like to show all whitewater world slides.
			context.parkIsOpen = context.IsSummer && context.IsWWWWeekend;
		}

		//Set of filter buttons based on data
		const navStringsDuplicates = service.payload.attractions.map((object: any) =>
			JSON.stringify({
				AttractionGroup: object.AttractionGroup,
				AttractionGroupUrl: object.AttractionGroupUrl,
				AttractionPark: object.AttractionPark,
			})
		);
		const uniqueNavStrings = [...new Set(navStringsDuplicates)];
		const navObjects = uniqueNavStrings.map((s: any) => JSON.parse(s));

		groupNav.push(
			<li
				key="all"
				className={groupUrl === 'all-attractions' || groupUrl === undefined || groupUrl === '' ? 'active' : ''}>
				<button onClick={e => onClickFilterHandle(e, 'attraction-group', 'all-attractions')}>
					{`All ${parkUrl === 'entertainment' ? 'entertainment' : 'attractions'}`}
				</button>
			</li>
		);

		/*
          Filter attraction data
    */

		filteredData = service.payload.attractions;

		if (parkUrl) {
			//filter attractions by park
			filteredData = service.payload.attractions.filter((i: Attraction) => {
				return i.AttractionPark.replace(' ', '-').toLowerCase() === parkUrl ? i : null;
			});

			//Get attraction-group buttons that match the park e.g. dw=rides
			navObjects &&
				navObjects.forEach((n, index) => {
					const attractionParkUrl = n.AttractionPark.replace(' ', '-').toLowerCase();
					if (attractionParkUrl === parkUrl) {
						groupNav.push(
							<li key={index} className={`${n.AttractionGroupUrl === groupUrl ? 'active' : ''}`}>
								<button onClick={e => onClickFilterHandle(e, 'attraction-group', n.AttractionGroupUrl)}>
									{n.AttractionGroup}
								</button>
							</li>
						);
					}
				});
		}

		if (openOnlyUrl === 'true') {
			filteredData = filteredData.filter(a => {
				return attractionIsOpen(a, context) ? a : null;
			});
		}

		if (groupUrl && groupUrl !== 'all-attractions') {
			filteredData = filteredData.filter(i => {
				return i.AttractionGroupUrl.toLowerCase() === groupUrl ? i : null;
			});
		}
		if (heightUrl) {
			filteredData = filteredData.filter(a => {
				const height: number = parseInt(heightUrl);
				return accompaniedUrl === 'true'
					? height >= a.AttributeMinHeightWithAdult && height < a.AttributeMaxHeight
					: height >= a.AttributeMinHeight && height < a.AttributeMaxHeight;
			});
		}

		if (thrillUrl) {
			filteredData = filteredData.filter(a => {
				return thrillUrl === a.AttributeThrill.toLowerCase();
			});
		}

		// push unique thrill-levels to nav object
		levelOptions.forEach((t, index) => {
			const disabled: boolean = choseThrill
				? false
				: filteredData.some(a => a.AttributeThrill.toLowerCase() === t.name)
				? false
				: true;
			thrillsNav.push(
				<li key={index} className={t.name === thrillUrl ? 'active' : ''}>
					<button
						className={`thrill-${t.label.toLocaleLowerCase()} ${disabled ? 'disabled' : ''}`}
						disabled={disabled}
						onClick={e => onClickFilterHandle(e, 'thrill', t.name)}>
						{t.label}
					</button>
				</li>
			);
		});

		if (ageUrl) {
			filteredData = filteredData.filter(a => {
				return parseInt(ageUrl) >= a.AttributeMinAge;
			});
		}
		if (parkUrl === 'entertainment') {
			let attractionEvents: AttractionEvent[] = [];
			filteredData.length > 0 &&
				filteredData.forEach(a => {
					//There needs to be a TimelineCard for each event. Events are stored as times in an array within each attraction.
					//So this will create a new array of attractionEvents, that can be sorted by time, then made into an array of TimelineCards
					a.EntertainmentTimes?.forEach(e => {
						let newEvent: AttractionEvent = Object.assign(
							{ EntertainmentTimeDetails: e.TimeStart, EntertainmentTimePeak: e.PeakOnly },
							a
						);
						//standardise event time to the same day so that sorting works
						newEvent.EntertainmentTimeDetails = new Date(
							2022,
							1,
							1,
							new Date(newEvent.EntertainmentTimeDetails).getHours(),
							new Date(newEvent.EntertainmentTimeDetails).getMinutes()
						);
						if (showPeakUrl === 'true') {
							//show them all
							attractionEvents.push(newEvent);
						} else if (!newEvent.EntertainmentTimePeak) {
							//only add event if it is NOT a peak time e.g. weekday schedule
							attractionEvents.push(newEvent);
						}
					});
				});

			attractionEvents =
				entertainmentTypeUrl.length > 0
					? attractionEvents.filter(a =>
							entertainmentTypeUrl.split(',').some(e => e === a.EntertainmentType?.toLowerCase())
					  )
					: attractionEvents;
			attractionEvents = attractionEvents.sort(
				(a, b) => +new Date(a.EntertainmentTimeDetails) - +new Date(b.EntertainmentTimeDetails)
			);
			//array of TimelineCards
			attractions =
				attractionEvents &&
				attractionEvents.map((ae, i) => {
					return <TimelineCard key={i} event={ae}></TimelineCard>;
				});
		} else {
			//create array of attraction cards
			attractions =
				filteredData &&
				filteredData.map((attraction, index) => {
					return <AttractionCard attraction={attraction} context={context} key={index}></AttractionCard>;
				});
		}
	}

	/*

      RENDER

  */

	return (
		<div className="attractions">
			<div className="attraction-filters">
				<div className="attractions-group-nav">
					<ul className={`tablets ${groupMenuOpen ? 'open' : ''}`}>{groupNav}</ul>
				</div>
				{context.park === 'whitewater-world' && (
					<>
						<p>Please note: Whitewater World is currently closed for the season.</p>
						<p>
							We will re-open{' '}
							{context.nextOpening &&
								new Date(context.nextOpening.DateFrom).toLocaleDateString([], { month: 'short', day: 'numeric' })}
						</p>
					</>
				)}
				{parkUrl !== 'entertainment' && groupUrl !== 'experiences' && groupUrl !== 'wildlife' && (
					<>
						<button
							className={`d-none btn more-filters ${filtersOpen ? 'open' : 'close'}`}
							onClick={e => setFiltersOpen(filtersOpen ? false : true)}>
							{' '}
							Find your perfect {parkUrl === 'whitewater-world' ? 'slide' : 'ride'}
						</button>
						<div className={`d-none ride-filters ${filtersOpen ? 'open' : ''}`}>
							<div className="filter-section">
								<div className="attractions-thrills filter-bar">
									<h4>Thrill Level</h4>
									<ul className="tablets">{thrillsNav}</ul>
								</div>
								<div className="attractions-open filter-bar">
									<h4>Open Today</h4>
									<button
										onClick={e => onClickFilterHandle(e, 'openOnly', '')}
										className={`btn open-attractions switch switch-${openOnlyUrl === 'true' ? 'on' : 'off'}
                  ${filtersOpen ? 'open' : ''}
                  `}>
										<span>{openOnlyUrl === 'true' ? 'Y' : 'N'}</span>
									</button>
								</div>
							</div>
							<div className="filter-section">
								<div className="rider-age filter-bar">
									<h4>Guest Age </h4>
									<Slider
										min={0}
										max={15}
										step={1}
										value={parseInt(ageUrl)}
										onChange={onAgeSliderHandle}
										tooltip={false}
										labels={{ 0: '0', 5: '5', 10: '10', 15: '15+' }}
										handleLabel={ageUrl}
									/>
								</div>
								<div className="rider-height filter-bar">
									<h4>Guest Height (cm)</h4>
									<Slider
										min={0}
										max={205}
										step={5}
										value={parseInt(heightUrl)}
										onChange={onheightSliderHandle}
										labels={{ 0: '0', 50: '50', 75: '75', 100: '100', 125: '125', 150: '150', 175: '175', 210: '210+' }}
										tooltip={false}
										handleLabel={heightUrl}
									/>
								</div>
								{parkUrl === 'dreamworld' && (
									<div className="attractions-accompanied filter-bar">
										<h4>With an Adult</h4>
										<button
											onClick={e => onClickFilterHandle(e, 'accompanied', '')}
											className={`btn switch switch-${accompaniedUrl === 'true' ? 'on' : 'off'}`}>
											<span>{accompaniedUrl === 'true' ? 'Y' : 'N'}</span>
										</button>
									</div>
								)}
							</div>
							<div className={`attractions-reset tablets ${filtersOpen ? 'open' : ''}`}>
								<button onClick={e => onClickFilterHandle(e, '', '')} className="btn">
									Reset Filters
								</button>
							</div>
						</div>
					</>
				)}
				{parkUrl === 'entertainment' && (
					<>
						{
							<div className="filter-options" style={{ display: `none` }}>
								<div className="filter-option">
									<h4>Weekend Schedule</h4>
									<button
										onClick={e => onClickFilterHandle(e, 'peak', '')}
										className={`btn switch switch-${showPeakUrl === 'true' ? 'on' : 'off'}`}>
										<span>{showPeakUrl === 'true' ? 'Y' : 'N'}</span>
									</button>
								</div>
							</div>
						}
					</>
				)}
			</div>
			<div className={`attractions-list ${parkUrl === 'entertainment' ? 'events' : ''}`}>
				{attractions.length > 0
					? attractions
					: 'There are no attractions fitting your criteria. Try changing some of the filters.'}
			</div>
		</div>
	);
};

export default Attractions;
